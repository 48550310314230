/*html, body {*/
/*    height: 100%;*/
/*    color: #222222;*/
/*}*/

/*.container{*/
/*    box-shadow: -1px -5px 5px 5px rgba(70,60,60,0.23);*/
/*    -webkit-box-shadow: -1px -5px 5px 5px rgba(70,60,60,0.23);*/
/*    -moz-box-shadow: -1px -5px 5px 5px rgba(70,60,60,0.23);*/
/*    background-color: #f8f8f8;*/
/*    padding: 0;*/
/*}*/
.ant-layout {
    background: none;
}

.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(0, 0, 0, 0.8);
}
.banner {
    background: rgb(255, 255, 255);
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 20px;
    padding-bottom: 30px;
}

/*.header {*/
/*    line-height: 68px;*/
/*    text-align: left;*/
/*    background-color: #f8f8f8 !important;*/
/*    font-size: 24px;*/
/*    text-align: left;*/
/*    padding-left: 0;*/
/*    padding-top: 10px;*/
/*    height:68px;*/
/*    padding-bottom: 10px;*/
/*    vertical-align: center;*/
/*}*/

.headerTitle{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.body {

}

/*.title {*/
/*    margin: 24px auto;*/
/*    text-align: center;*/
/*    width: 600px;*/
/*    font-size: 36px;*/
/*}*/

.subTitle{
    text-align: left;
    font-size: 16px;
    margin-top: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-indent: 20px;
}

.actionTitle {
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
}

.postCard {
    margin-bottom: 20px;
    text-align: left;
    padding: 24px 0;
    border-bottom: 1px solid #e8e8e8;
}

.postCard .ant-card-body {
    padding: 0;
}

.postCard .cardBox {
    vertical-align: top;
    float: left;
}

.postCard .cardBox .cardTitle {
    font-size: 16px;
    padding: 0;
    font-weight: bold;
    margin-bottom: 10px;
}

.postCard .cardBox .cardDescription {
    font-weight: normal;
    font-size: 14px;
    font-style: italic;
    color: #313131;
}

.loading {
    width: 600px; height: 30px;

    position: absolute; left: 50%; top: 50%;

    transform: translate(-50%, -50%);    /* 50%为自身尺寸的一半 */
}

.blank_input{
    background-color: #ffffff;
    border-bottom:  1px solid rgb(232, 232, 232);
    padding: 5px;
    width: 90%;
    margin-left: 5%;
    color: #222222;
}

.anticon svg {
    margin-top: -5px;
}

div.DraftEditor-root {
    height: 400px;
    overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}
