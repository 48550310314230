body {
  margin: 0;
  font-family: "Poppins", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "~antd/dist/antd.css";
@import "t-styles/style.css";
@import "t-styles/home.css";
@import "t-styles/detail-page.css";
@import "t-styles/booking-sign_up.css";
@import "t-styles/blog.css";




.ant-btn-primary {
  background-color: #589442;
  border-color: #589442 ;
}

.t_item_title {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 65px 15px 10px 15px;
  background: -moz-linear-gradient(top, transparent 5%, black 100%);
  background: -webkit-linear-gradient(top, transparent 5%, black 100%);
  background: linear-gradient(to bottom, transparent 5%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}
.t_item_title h3 {
  font-size: 16px;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1;
}

article.blog {
  text-align: left;
}

article.blog  p{
  font-size: 14px;
  font-size: 0.875rem;
  color: #444;
}

.nav-link {
  display: block !important;
  padding: 0 15px !important;
  height: 30px !important;
  font-size: 0.9375rem !important;
  color: #444 !important;
  border: none !important;
  font-weight: bold;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid #589442 !important;
  color: #589442 !important;
  background-color: transparent !important;
}

.ant-select-dropdown {
  z-index: 999999 !important;
}

.sub_title {
  float: left;
  color: #444;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-top: 16px;
  font-style: italic;
  border-left: 2px solid #444;
  margin-left: 20px;
  padding-left: 20px;
  height: 14px;
  line-height: 14px;
}

.strip {
  cursor: pointer;
}
